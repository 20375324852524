@import "variables";
@import "mixins";

.subscribe-input {
    width: auto !important;
    padding: .3rem .75rem !important;
    background-color: $whiteColor !important;
}

.menu-div-header {
    padding: 10px 0px;
}

.menu-item-div {
    padding: 10px 0px;
}

/* css for toggle check box */
.facility-details {
    .checkbox {
        float: right;
    }

    .checkbox1 {
        float: center;
    }

    .checkbox,
    .checkbox1 {

        width: 40px;
        height: 1.2rem;
        border-radius: 20px;
        border: 2.5px solid $LightGray;
        background: $veryLightGray;
        color: $lightBlack;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    .checkbox--on {
        flex-direction: row;
        background: $mediumLightGreen;
        border: 2.5px solid $mediumLightGreen;
        color: $successColor;
        flex-direction: row-reverse;

    }

    .checkbox--on .checkbox__ball {
        /* box-shadow: 0px 3px 4px 0px rgb(29, 80, 29); */
        margin-right: 0px;
        /* background: #1D9824; */
        border-radius: 50%;
    }

    .checkbox--on .checkbox__ball {
        background: $successColor;
    }
}

#submenu-section-div .checkbox--on {
    background: $lightYellow;
    border: 2.5px solid $lightYellow;
}



#submenu-section-div .checkbox--on .checkbox__ball {
    background: $secondaryColor;
}

.checkbox__ball {
    /* background: whitesmoke; */
    background: $grayishRed;
    ;
    /* box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72); */
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 0px;

}

.checkbox__text {
    font-size: 1.8rem;
    margin-left: 5px;
    margin-right: 5px;
}

/* end css for checkboc */

.menu-list-row {
    /* padding: 10px; */
    // box-shadow: 0px 0px 3px 1px #04a96d47;
    box-shadow: $selectInputShadow;
    margin: 2% 0px;
    max-height: 160px;
    min-height: 160px;
}

.menu-item-column {
    line-height: 12px;
}

.module-head-div {
    padding: 10px 3px;
    background-color: $whiteColor;
    /* border-bottom: 1px solid #b8efdc; */
    /* box-shadow: 0px 2px 0px #b8efdc; */
}

.sb-hr {
    color: #04a96d47;
    width: 90%;
    box-shadow: 0px 2px 4px $selectInputShadow;
}

.menu-name {
    font-size: 14px;
    font-weight: 800;
}

.submenu-row {
    margin-bottom: 2%;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.submenu-name {
    font-size: 15px;
    font-weight: 600;
}

.section-sunmenus {
    padding: 14px 0px;
    max-height: 100px;
    min-height: 100px;
    display: flex;
    /* align-items: center; */
    width: 100%;
}

.section-sunmenus::-webkit-scrollbar {
    width: 3px;
    background-color: $lightGrayishCyanLimeGreen;
}

.section-sunmenus::-webkit-scrollbar-thumb {
    background: $successColor;
}

.toggle-btn-div {
    display: flex;
    justify-content: flex-end;
}

.scroll {
    overflow-y: scroll;
}

.submenus {
    padding-right: 3px;
}

.matrix-div {
    /* box-shadow: 0px 0px 3px 1px #04a96d47; */
    margin: 15px 10px;
    padding: 15px;
}

.input-check {
    /* position: absolute; */
    top: 0;
    left: 0;
}

.checkmark {
    position: absolute;
    top: 0;

}

/* suscription page css */
.sb-subscription-module {
    padding: 20px 10px;
}

.sb-module-card {
    margin: 10px 0px;
    padding: 15px 5px;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    border: none !important;
    transition: transform 0.2s;
    box-shadow: inset $darkCyanLimeGreen 0 2px 2px;
    background: $whiteColor;
    position: relative;
    min-height: 250px;
    z-index: 1 !important;
    cursor: pointer;
    &.sb-disable-card {
        background: $veryLightGray !important;
        cursor: auto !important;
        opacity: 0.8 !important;
    }
    .sb-sbscription-img{
        width: 70px;
        border-radius: 50%;
        background: $iconBox;
        padding: 12px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 90%;
        }
    }
    &:hover {
        transform: scale(1.01);
        box-shadow: $card_shadow_hover;
    }
    /* box-shadow: rgba(198, 176, 255, 1) 0px 12px 20px, rgba(23, 0, 102, 0.2) 4px 4px 20px, rgba(0, 0, 0, 0.1) 0px 0px 20px; */
}

.card-body {
    text-align: center;
}

.sb-module-card img {
    width: 60px;
    height: 60px;
}

.sb-card-title {
    // background: $header_Gradient;
    background: $primaryColor;
        -webkit-background-clip: text;
        color: transparent;
    // color: $primaryColor;
    font-weight: 600;
    font-size: 16px;
}

.sb-matrix-div {
    position: relative;
    padding: 10px 0px;
}

.subscription-back-btn {
    padding: 5px 10px;
    background-color: $primaryColor;
    cursor: pointer;
}

.module-btn-div {
    display: flex;
    justify-content: center;
}

.module-page-header {
    display: flex;
    align-items: center;
    background-color: $whiteColor;
    border-bottom: 1px solid $lightGrayishRed;
    padding: 5px;
    position: sticky;
    /* top: 0; */
}

input[type=checkbox].css-checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type=checkbox].css-checkbox+label.css-label {
    padding-left: 20px;
    height: 15px;
    display: inline-block;
    line-height: 15px;
    font-size: 15px;
    vertical-align: middle;
    cursor: pointer;
    /* background-image: url("../../public/images/subscription/images-tick-mark.png") !important; */
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}

input[type=checkbox].css-checkbox-active+label.css-label {
    /* background-position: 0 -15px; */
    background-image: url("../../public/images/subscription/images-check-right.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}

/* .css-label {
    background-image: url("../../public/images/subscription/images-check-right.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
} */

.sb-module-footer {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}

.sb-menu-count-devider {
    border-right: 3px solid $lightGray;
    margin: 2px 15px;
    height: 20px;
}

.sb-menu-count-icon {
    color: $secondaryColor;
}

/* dropdown */
.subsription-page .css-1nmdiq5-menu {
    z-index: 9999 !important;
    left: -30px;
}

#react-select-4-listbox {
    /* position: fixed; */
    display: block;
    z-index: 999 !important;
    overflow: visible;
}

.success-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 10px;
}

.page-top-bar-dropdowns .react-select-list__control--menu-is-open {
    z-index: 9999 !important;
    visibility: visible;
}

/* dropdown */
/* suscription page css */