@import "variables";

#header {

    // position: absolute;
    .pro-sidebar {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1009;
        box-shadow: 2px 2px 2px $primaryPaleLight !important;

        &.expanded-bar {
            min-width: 15.5vw;
            width: 15.5vw;
            transition: all 0.35s ease;
            transform: translateX(0);

        }

        &.collapsed-bar {
            min-width: 5.5vw;
            width: 5vw;
            transition: all 0.35s ease;
            transform: translateX(0);
        }

        .downloadApp-part {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            border-top: 0.06rem solid lightgrey;
            background: $whiteColor;
            cursor: pointer;

            .download-app {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 8.6vh;
                padding: 0.5rem;

                .material-symbols-outlined {
                    font-size: 1.5rem;
                    margin-right: 0.5rem;
                }

                .text {
                    font-size: 1rem;
                    color: $primaryColor;
                }
            }
        }
    }
}

.side_bar_header_main {
    width: 100%;
    height: 6.5rem;
    background-color: $whiteColor !important;
    display: flex;
    align-items: center !important;
    justify-content: center;
    z-index: 10;
    padding: 0.6rem;
    position: relative;
    .essae_company_logo {
        width: 100%;
    
        img {
            padding: 1.15rem 1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 95%;
            height: 100%;
        }
    }
}

.travelize_logo {  
    position: relative;  
    width: 100%;
    text-align: center;
    img {
        padding: 0.5rem;       
        width: 80%;
        height: 100%;
    }
}

.salesbee-logo {
    width: 100%;
}

.fa-icon-div {
    width: 20px;
}

.sub-icon-div {
    width: 18px;
    display: flex;
}

.nav-link {
    margin-left: 0px;
}

.sidebar_content {
    background-color: $whiteColor;
    overflow-y: auto;
    padding: 0 0 1rem 0;
    height: 82vh;

    .expanded-nav-bar {
        li {
            list-style: none;
            padding: 0.65rem;

            &:hover a {
                color: $whiteColor;
            }

            &:focus a {
                color: $whiteColor;
            }
        }

        a {
            text-decoration: none;
            color: $primaryText_Color;
        }

        .xn-text {
            color: $primaryText_Color;
            margin-left: 8px;
            font-size: $fz14;
            font-weight: $fwt400;
        }
    }
}

.sidebar-item .nav-link {
    padding: 0px !important;
}

.drop-main:hover {
    color: $primaryColor !important;
}

.drop-main:hover .sidebar-title {
    color: $primaryColor !important;
}

.nav-link-item {
    align-items: center;
    min-width: 185px;
}

.expanded-nav-bar {

    .nav-link-item.active,
    .sidebar-item.plain.active {
        position: relative;
        color: $primaryColor !important;
        // background-color: $primaryColor !important;
        background-image: $sidebarActive;
        // border-left: 4px solid $secondaryColor !important;
    }

    .sidebar-item.plain:hover {
        position: relative;
        color: $primaryColor !important;
    }

    .nav-link-item.active::before,
    .sidebar-item.plain.active::before {
        content: "";
        height: 30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 3px;
        background-color: $primaryColor;
        position: absolute;
        left: 0;
        border-radius: 0 3px 3px 0;
    }


    .nav-link-item.active .sidebar-title {
        color: $primaryColor;
    }
}


.sidebar-item {
    cursor: pointer;

    &:hover {
        cursor: pointer;
    }

    .sidebar-content {
        height: 0;
        overflow: hidden;

        .nav-link-item:hover {
            color: $primaryColor;
        }

        &.sub-active .nav-link-item {
            color: $primaryColor;

            .xn-text {
                color: $primaryColor;
                font-weight: 500;
            }
        }

        .nav-link-item .bi {
            font-size: $fz10;
        }
    }

    &.open .sidebar-content {

        height: auto;
        vertical-align: middle;


        .nav-link-item .xn-text {
            font-size: $fz14;
        }

        .nav-link-item {
            padding: 0.5rem 0.65rem 0.5rem 1.85rem;

        }
    }



    &.open {
        .nav-link-item.active .sidebar-title .toggle-btn {
            cursor: pointer;
            transition: transform .1s;
        }

        .nav-link-item.active>.sidebar-title .toggle-btn {
            transform: rotate(90deg);
        }

        .active .sidebar-title {
            color: $primaryColor;
        }
    }

    &.plain {
        &.active a {
            color: $whiteColor;
            text-decoration: none;
        }

        i {
            display: inline-block;
            width: 1.7em;
        }
    }
}

.sidebar-title {
    display: flex;
    align-items: center;
    color: $veryDarkGrayishBlue;

    &:hover {
        color: $primaryColor;
    }

    .bi-chevron-right.toggle-btn {
        margin-left: auto;
        margin-right: 10px;
    }
}




/* COLLAPASED NAV BAR START */

.collapsed-nav-bar {
    overflow-y: hidden;
    padding: 10px;

    li {
        list-style: none;
        text-align: center;

        // &:hover {
        //     background-color: $blackColor;
        // }

        >a {
            text-decoration: none;
            color: $nav_link_Textcolor;
        }

        &:hover>a {
            color: $primaryColor;
        }

        >a.active {
            color: $primaryColor;
        }
    }

    .fa {
        margin: 10px 0px;
        font-size: $fz18;
        padding: 5px;
        font-weight: $fwt600;
    }
}

.profile-mini {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.45rem;

    .profile-image {
        padding: 2px;
    }
}

.profile-image img {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 10px;
    padding: 0px;
    background-color: $whiteColor;
    object-fit: contain;
}

/* COLLAPASED NAV BAR END */


/* width */
.sidenavbar-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    // background-color: $secondaryColor !important;
    border-radius: 6px !important;
}

/* Handle */
.sidenavbar-scroll::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 10px !important;
}

/* Handle on hover */
.sidenavbar-scroll:-webkit-scrollbar-thumb:hover {
    // background: rgb(168, 168, 168);
    background: $grayColor;
}

.setting-image {
    cursor: pointer;
}

.setting-image img {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @-ms-keyframes spin {
        from {
            -ms-transform: rotate(0deg);
        }

        to {
            -ms-transform: rotate(360deg);
        }
    }

    @-moz-keyframes spin {
        from {
            -moz-transform: rotate(0deg);
        }

        to {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}


/* New CSS */
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    color: $veryDarkGrayishBlue !important;
}

.pro-sidebar .pro-menu {
    padding: 0px;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover,
.pro-item-content:hover {
    color: $primaryColor !important;
}

.pro-sidebar .pro-menu li.pro-menu-item.sidebar-item.plain.active,
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item.active {
    color: $primaryColor !important;
    background-image: linear-gradient(to right, rgba(66, 138, 121, 0.1) 30%, transparent 100%);
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item {
    padding: 0px !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    background-color: $whiteColor !important;
}


.powered-by-div {
    text-align: center;
    margin-top: 25px;
}

.powered-by-div span {
    color: $successColor;
    font-size: 10px;
}
/* New CSS */


/// NEW SIDEBAR CSS START
.main-modules {
    li {
        list-style: none;
    }

    .module-name {
        .module-title {
            padding: 10px 5px;
            display: flex;
            align-items: center;
            color: #3b4863;
            .xn-text{
                margin-left: 6px;
            }
            .toggle-btn{
                margin-right: 5px;
                margin-left: auto;
            }
        }
    }

    .module-menu {
        display: none;
        // padding-left: 15px;
        .menue-title{   
            padding: 10px 5px 10px 20px;
            display: flex;
            align-items: center;
            .xn-text{
                margin-left: 8px;
            }
            .toggle-btn{
                visibility: visible;
                margin-right: 10px;
                margin-left: auto;               
                &.none{
                    visibility: hidden;
                }
            }
        }
        .sub-menue{
            .nav-link-item{
                padding: 10px 5px;
                margin-left: 15px;
                .icon{
                    padding-right: 10px;
                }
            }
        }
    }

    &.open {
        box-shadow: $card_box_shadow;
        .module-name {
            .module-title {       
                position: relative;
                background-image: $sidebarActive;
                color: #3b4863;
                &::before{
                    content: "";
                    height: 30px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 3px;
                    background-color: $primaryColor;
                    position: absolute;
                    left: 0;
                    border-radius: 0 3px 3px 0;
                }
                .toggle-btn{
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }

        .module-menu {
            display: none;
            
            .menue-title {
                color: #3b4863;
            }
            
            .sub-menue {
                .nav-link-item {
                    display: none;
                }
            }
            
            &.open {
                display: block;
                // background-color: $primaryVeryLight;.
                .menue-title{
                    &.open{
                        background-image: $sidebarActive;
                        .toggle-btn {
                            color: $primaryColor !important;
                            -webkit-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            -o-transform: rotate(90deg);
                            transform: rotate(90deg);
                            }
                    }
                } 
                
                
                   
                .sub-menue {
                    .nav-link-item {
                        display: none;
                        padding: 10px 5px 10px 20px;
                        color: #3b4863;
                        
                    }

                    &.open {
                        .nav-link-item {
                            display: block;
                            &:hover{
                                color: $primaryColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

/// NEW SIDEBAR CSS END
