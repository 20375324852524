@import "mixins";
@import './variables';


.loading-animation-page {

    .sound-wave {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        width: 50px;
        height: 30px;

        div {
            height: 30px;
            display: block;
            width: 10px;
            height: 6px;
            border-radius: 8px;
            background-color: orange;
            animation: audio-wave 2.2s infinite ease-in-out;

            &:nth-child(2) {
                left: 5px;
                animation-delay: 0.2s;
            }

            &:nth-child(3) {
                left: 10px;
                animation-delay: 0.4s;
            }

            &:nth-child(4) {
                left: 15px;
                animation-delay: 0.6s;
            }

            &:nth-child(5) {
                left: 20px;
                animation-delay: 0.8s;
            }

            &:nth-child(5) {
                left: 25px;
                animation-delay: 1s;
            }
        }
    }
}

@keyframes audio-wave {
    0% {
        height: 5px;
        transform: translateY(0px);
        background-color: #ff8e3a;
    }

    25% {
        height: 30px;
        transform: translateY(-5px) scaleY(1.2);
        background-color: #ed509e;
    }

    50% {
        height: 5px;
        transform: translateY(0px);
        background-color: #9c73f8;
    }

    100% {
        height: 5px;
        transform: translateY(0px);
        background-color: #0fccce;
    }
}