@import "variables";
@import "mixins";


.initechNode {
    // border: solid 3px red;
    border-radius: 3px;
    padding: 5px;
    width: 150px;
    display: inline-block;
    position: relative;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
    border-right: solid 3px $primaryColor;
}

#initechOrgChart .nodeLineBorderTop {
    border-top: solid 3px $primaryColor;
}

#initechOrgChart tbody tr {
    border-bottom: none !important;
}

#initechOrgChart tbody tr td {
    padding: 0px !important;
    border-bottom: none !important;
}

.hierarchy-image img {
    width: 40px;
    height: 40px;
    border: 5px solid $primaryColor;
    border-radius: 50%;
    padding: 5px;
}

   .hierarchy-positions {
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    color: $blackColor;
    background-color: $modelhfbackgroundColor;
}
.hierarchy-div{
    overflow: auto;
    padding: 20px;
}

/* Add this to your CSS */
.hierarchy-hover-div {
    display: none; /* Initially hide the div */
    position: absolute;
    // top: 100%;
    left: 130px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1;
    border-radius: 3px;
    // width: 300px;
    // height: 100%;
    padding: 20px;
  }
  
  .hierarchy-hover-div.visible {
    display: block; /* Show the div when visible class is applied */
  }
  .user-email{
    display: flex;
    justify-content: flex-start;
  }
  .user-email p{
    margin-right :  8px ;
  }