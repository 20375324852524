@import "variables";
@import "mixins";

.add-user-main-logo-image .add-user-images {
    height: 200px;
    left: 50%;
    max-width: 200px;
    object-fit: fill;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.add-user-main-logo-image {
    background-image: radial-gradient(#fff, #fff, #e4e4e4 70%);
    border-radius: 4px;
    max-height: 220px;
    min-height: 220px;
    padding: 10px;
    position: relative;
    width: 100%;
}

.add-user-image-container-div {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.add-user-title {
    display: flex;
    justify-content: flex-start;
    // margin-left: 30px;

    h4 {
        color: $primaryColor;
        font-weight: 500;
        // margin-left: 80px;
        // font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-family: "Roboto", sans-serif;
        ;
    }
}

.modal-fullscreen-xxl-down {
    max-width: 100% !important;
    margin: 0 !important;
}

.add-user-container {
    padding: 0px 80px;
}

.add-bulk-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-machine-page-container {
    // background: #eef4f8;
    position: relative;
    overflow: hidden;
    height: 100vh;

    .bubble-image {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        // z-index: -1;

        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 60%;
        }
    }

    .circle-image {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        padding: 40px 0;
        // z-index: -1;

        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 60%;
        }
    }

    .bubble-image-small {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        top: 70px;
        left: 0;
        // z-index: -1;

        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 40%;
        }
    }

    .circle-image-small {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: left;
        top: 70px;
        left: 0px;
        // z-index: -1;

        img {
            -webkit-animation: rotate-center 100s linear infinite both;
            animation: rotate-center 100s linear infinite both;
            width: 40%;
        }
    }



    .left-image {
        position: absolute;
        top: 0;
        left: 0;
    }

    .lines-image {
        position: absolute;
        top: 300px;
        left: 0;

        img {
            width: 40%;
        }
    }

    .right-image {
        position: absolute;
        right: 0;
        top: 0;
    }

    .add-page-save-cancel {
        padding: 15px 10px 10px 15px;
        border-top: 1px solid $borderColor;
        display: flex;
        justify-content: flex-end;
        border-radius: 0px 0px 6px 6px;
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.add-bulk-uplaod-btn {
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
}

.bulk-upload-static-image img {
    max-width: 100%;
}