 @import './variables';

 .order-status-div{
    display: inline-block;
    height: 33.333%;
 }
 .order-status-div1{
    display: flex;
    padding: 10px;
 }
 .circle{
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: relative;
    cursor: pointer;
    background: $statusGreen;
    margin-left: 10px;
}
.order-status-inner-div{
    margin-left: 10px;
}
.order-confirm-status{
    margin-bottom: 10px;
}
.progressLine{
    background: $lightBackgroundTheme;
}
.line{
    background: $statusGreen;
    height: 100px;
    width: 4px;
    margin-left: -8px; 
    margin-bottom: -22px; 
}
.orderTime{
    width: 130px;
}