@import "variables";
@import "mixins";

p {
    color: $primaryText_Color;
}

.page-content-dash {
    position: relative;    
}

.sb-card-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-dashboard {
    border-radius: 0px !important;
    border: none;
    width: 20%;
    padding: 0px 10px;
    background-color: transparent;
    // box-shadow: $card_BoxShadow;

    .card-body {
        &.first-card {
            width: 100%;
            padding: 5px !important;
            // background: $card_background !important;
            .card-left-img {
                background: transparent !important;
            }
        }
        
        background-color: $primaryVeryLight;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        max-height: 100px;
        border-radius: 10px;

        &:hover {
            border-radius: 0px !important;
            border: none;
            box-shadow: $card_shadow_hover;
        }
    }

}

.card-row {
    align-items: center;
    width: 100%;
}

.verticle-line {
    border-right: 1px solid $secondarySemiLight;
    height: 30px;
    padding-left: 5px;
}

.sb-grap-text {
    line-height: 20px;

        .meet-detls {
            .tab-view {
                display: none;
            }
        }
}

.grap-imag {
    width: 80%;
    padding: 0px;
}

.grap-box {
    width: 8px;
    height: 8px;
}

.box1 {
    background-color: $primaryColor;
}

.box2 {
    background-color: $primarySemiLight;
}

.box3 {
    background-color: $secondarySemiLight;
}

.attend-card,
.company-card,
.claim-card {
    padding: 0 0.6rem;
    width: 100%;
}

.claim-card {
    .card-head {
        &.desk-view {
            display: block;
        }

        &.tab-view {
            display: none;
        }
    }
}

.total-company {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.client-card {
    padding: 0px 5px;
}

.client-text {
    font-size: 18px;
    font-weight: 400 !important;
}

.sb-grap-text p {
    font-size: 13px;
}

.card-left-img {
    display: flex;
    padding: 6px;
    justify-content: center;
    // background: $card_background;
    border-radius: 4px;
    align-items: center;
}
.card-imag {
    width: 90%;
    // padding: 0px 5px;
}

.meeting-row-div {
    padding: 10px;
}

.sb-meeting-grap-row {
    padding: 6px 2px;
    display: flex;
    align-items: center;
}

.badge {
    padding: .35em .25em;
    font-size: .60em;
    min-width: 30px;
}

.bd1 {
    background-color: $primaryColor !important;
}

.bd2 {
    background-color: $primarySemiLight !important;
}

.bd3 {
    background-color: $paleGraphGreen !important;
}

.grap-img {
    padding: 25px 0px;
}



.act-body {
    width: 100%;
    height: 100%;
}

.card-right-data {
    display: flex;
    align-items: center;
    padding: 0px;
    text-align: center;
    height: 100%;

    a {
        color: $primaryText_Color;

        &:hover {
            color: $primaryText_Color;
        }
    }
}

.meeting-grap-section {
    position: relative;
}

.sb-complete-meeting {
    position: absolute;
    left: 38%;
}

.sb-claims {
    position: absolute;
    left: 40%
}

.sb-complete-meeting p {
    font-size: 12px !important;
}



.sb-meeting-grap-div {
    background-color: $whiteColor;
    border-radius: 4px;
}



.act-num {
    font-size: $fz15;
}

.dashboard-user-details {
    margin-top: 20px;
}

.user-activity-right-dash {
    height: 420px;
    overflow-y: auto;
    background-color: $whiteColor;
}

.dash-board-left-side,
.user-activity-right-dash {
    box-shadow: $card_shadow_hover;
    border: 0px;
    background-color: $whiteColor;
    border-radius: 4px;
}

.see-all-user-lish-dash {
    padding: 10px 3px;
    background-color: $whiteColor;
    position: absolute;
    bottom: 0;
    width: 100%;

    .see-all-link {
        color: $primaryColor;
        text-decoration: none;
        margin-left: 10px;
    }
}

.sb-db-table-th {
    padding: 0.5rem 0.3rem !important;
    font-size: 18px;
    // width: 23%;
}

.sb-ds-link {
    display: flex;
    flex-direction: column;    
    align-items: center;
}

.activity-page-link,
.attendance-page-link {
    text-decoration: none;
    color: $blackColor;

    &:hover {
        color: $blackColor;
    }
}

.sch-meeting-head img,
.direct-visit-head img {
    width: 20px;
    height: 20px;
}

.meeting-grap-head {
    background-color: $whiteColor;
    box-shadow: 0px 2px 2px #eee !important;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.sort-left-dash {
    font-weight: $fwt600;
    font-size: $fz15;
}

.user-list-top-head {
    box-shadow: 0px 2px 2px #eee !important;
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .sort-left-dash {
        display: inline-flex;

        i {
            margin-left: 10px;
        }
    }

    .selct-drop-by-dash {
        font-weight: 600;
        font-size: $fz16;
        float: right;
    }
}

.act-heading,
.refresh-activity {
    font-weight: 500;
    font-size: $fz16;
}

.userlist-thead-activity-table {
    background-color: $table_titleTop_backgroundColor;
    box-shadow: 0px 2px 2px #eee !important;
    padding: 5px 15px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;

    .act-top-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .act-heading {
            font-weight: 600;            
        }
    }

   
}

.user-activity-data {
    position: relative;   
}

.map-conrtainer {
    min-height: 550px;
    max-height: 550px;
    background-color: $whiteColor;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.map-element {
    height: 550px - 0;
    width: "100%";
    border-radius: 6px;
    margin: 2px;
}

.user-activity-data-map {
    width: 100%;
    position: relative;
    
    .user-activity-rowdata {
        padding: 10px !important;
    }


    td {
        padding: 8px 6px !important;
    }
}

.user-activity-data-col {   
    position: relative;   
    .user-activity-rowdata {
        padding: 5px !important;
        overflow-y: auto;
        min-height: 820px;
        max-height: 820px;
    }

    .act-right-body {
        background-color: $activUserBackground;
        padding: 8px 8px;
        margin: 10px 0px;
        border-radius: 6px;
        box-shadow: $card_box_shadow;        
    }

    td {
        padding: 8px 6px !important;
    }
}

.dashboard.user-meeting-dash-table {
    height: 360px;

    td:nth-child(1) {
        text-align: left;
    }

    td {
        text-align: center;
    }
}

.sb-db-map {
    padding: 0px 10px;
}


.activity-search {
    justify-content: flex-end;

    input {
        max-width: 250px;
        border-radius: 0px !important;
    }
}

.table-user-image {
    position: relative;
    text-align: center;
    align-items: center;
    padding: 10px 5px;
    // margin-left: 10px;

    h6 {
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: $fz13;
        font-weight: $fwt600;
        color: $textHeadColor;
    }

    p {
        padding: 2px;
        font-size: $fz11;
        font-weight: $fwt600;
        color: $primaryColor;
        cursor: pointer;
    }
}

.table-user-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.table-responsive::-webkit-scrollbar,
.user-activity-data-col::-webkit-scrollbar,
.user-activity-rowdata::-webkit-scrollbar {
    width: 4px
}

.table-responsive::-webkit-scrollbar-thumb,
.user-activity-data-col::-webkit-scrollbar-thumb,
.user-activity-rowdata::-webkit-scrollbar-thumb {
    background: $secondaryColor;
}

.css-41abqd-MuiTableContainer-root::-webkit-scrollbar:horizontal {
    height: 5px
}

.css-41abqd-MuiTableContainer-root::-webkit-scrollbar-thumb:horizontal {
    background: $secondaryColor;
}

.active-user-image {
    position: relative;
}

.table-data-user-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid $whiteColor;
    box-shadow: 0px 0px 4px 1px $secondarySemiLight;
    object-fit: cover;
}

.td-user-location {    
    font-size: $fz13;
    font-weight: $fwt400;
}

.active-img-col {
    display: flex;
    justify-content: center;
    align-items: center;
}


.active-user-row {
    padding: 5px 0px;
}

.act-icon {
    min-width: 50px;
    position: relative;
    margin: 3px;
}

.activity-icons {
    width: 25px;
    height: 25px;
    padding: 3px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $whiteColor;
    // box-shadow: $card_BoxShadow;

    // &.meeting {
    //     // border-radius: 10px !important;
    //     // width: 30px;
    //     // height: 30px;
    // }

    .fa,
    .fas {
        font-size: $fz16;
        color: gray;
    }
}



.user-activity-images {
    display: flex;
    position: relative;
    padding: 15px 0px 0px 0px;
    text-align: center;
    justify-content: space-around;

    .activity-icon-row {
        display: flex;
        flex-direction: row;
    }

    p {
        font-size: $fz10;
        margin-top: 5px;
        margin-bottom: 0px;
        font-weight: 500;
        color: $primaryColor;
        word-wrap: break-word;
    }

    .recent-activity-meeting-count {
        position: absolute;
        top: -2px;
        right: 16px;
        font-size: $fz8;
        background-color: $primaryColor;
        padding: 0px 4px;
        border-radius: 10px;
        color: white;
    }
}

.user-activity-table {
    width: 100%;
}

.no-activity-found {
    padding: 100px 20px 20px;
    text-align: center;

    .no-active-time {
        font-size: 28px;
        font-weight: 600;
        color: $primaryPaleLight
    }

    .no-active-date {
        font-size: 16px;
        font-weight: 600;
        color: $primaryPaleLight
    }

    .no-active-text {
        font-size: 15px;
        font-weight: 600;
        color: $primaryPaleLight;
        padding: 15px;
    }

    img {
        width: 100%;
    }
}

.refresh-button {
    margin-right: 5px;
    background-color: transparent;
    border: none;

    .fa-refresh {
        color: $primaryColor;
    }

    &:hover {
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }
}


@media screen and (max-width: 476px) {
    .card-right-data {
        padding-left: 0px !important;
        text-align: center;
    }
}

@media screen and (max-width: 1280px) {

    .act-icon {
        min-width: 40px;
    }

    .activity-icons {

        .fa,
        .fas {
            font-size: $fz18;
        }
    }

    .recent-activity-meeting-count {
        position: absolute;
        top: -8px;
        right: 41px;
    }

}

/* SCROLL BAR CSS with Styling START */
/* width */
.user-activity-right-dash::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.user-activity-right-dash::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.user-activity-right-dash::-webkit-scrollbar-thumb {
    background: $scrollerThumbColor;
    border-radius: 0px;
}

/* Handle on hover */
.user-activity-right-dash:-webkit-scrollbar-thumb:hover {
    background: rgb(102, 102, 102);
}


.dash-board-left-side,
.user-activity-right-dash {
    height: auto;
}
/* SCROLL BAR CSS with Styling END */




@media screen and (min-width: 1400px) {
    .custom-apex {
        height: 260px !important;
        min-height: 260px !important;
    }

    .custom-apex .apexcharts-canvas {
        height: 260px !important;
    }

}


// ---** APEX CHART CSS START **---- //


.apexcharts2ab3iy05 {
    z-index: 2 !important;
    overflow: visible !important;
}

.meetings-graph .apexcharts-canvas img,
.meetings-graph .apexcharts-canvas .apexcharts-svg {
    margin-left: 0px !important;
}

.meetings-graph .apexcharts-canvas {
    position: inherit !important;
}

// ---** APEX CHART CSS END **---- //


// CSS FOR TABLE
.thead-cell {
    min-width: 120px !important;
}

// .thead-cell:last-child, .table-row td:last-child {
//     position: sticky !important;
//     right: 0;
// }




.meeting-data-status {
    padding: 50px 0px;
}

.action-div-class {
    display: flex;
    align-items: center;
    justify-content: center;
}


.custom-icon {
    width: 10px;
    height: 10px;
    box-shadow: $secondarySemiLight 0px 5px 20px 0px;
}

.mrkr-img-div {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    animation: blink 1s infinite;

}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

.marker {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.marker:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 34px;
    height: 34px;
    border: 2px solid $dangerColor;
    border-radius: 50%;
    box-sizing: border-box;
}

.marker img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.sb-db-tbody td {
    font-size: 18px;
    height: 30px;
}

.sb-data-col {
    padding: 15px 10px;

}

.table-div-dashboard {
    background-color: $whiteColor;
    padding: 15px;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: $card_box_shadow;

    &.meeting-graph {
        border-start-start-radius: 6px !important;
        border-end-start-radius: 6px !important;
    }

    &.graph-card {
        box-shadow: $card_box_shadow;
        border-start-end-radius: 6px !important;
        border-end-end-radius: 6px !important;
    }

    &.activity-doard {
        border-radius: 6px;
    }

    &.third-row-div {
        border-radius: 6px;
        height: 360px;
    }

    .meetings-graph {
        width: 100% !important;
        height: auto !important;
        position: relative;
       
    }

    .claim-status-chart {
        width: auto !important;
        height: auto !important;
    }

    .ord-comp-meet-claim-data {
        .title-n-data {
            min-height: 100px;
            max-height: 100px;
            padding: 5px 10px;
            display: flex;
            cursor: pointer;
            border-radius: 6px;
            flex-direction: column;
            justify-content: center;

            &:hover {
                background-color: $tableRowHover !important;
            }

            .data-title {
                font-size: $fz14;
                font-weight: $fwt500;
                margin-bottom: 5px;
            }

            .data-value {
                font-size: $fz18;
                color: $primaryColor;

                i {
                    font-size: $fz16;
                    padding-right: 5px;
                }
            }
        }
    }
}

.hr-tag {
    margin: "2px 0px";
    color: $primarySemiLight;
}

.sort-div {
    .form-control {
        font-size: 12px;
        padding: 2px 13px !important;

        &.activity-search {
                line-height: 28px;
                width: 16rem;
                border: none !important;
                box-shadow: $card_shadow_hover;
                background-color: $whiteColor;
                border-radius: 20px;
                // margin-right: 10px;
            }
    }
}

.dashboard-activity-search {
    position: relative;

    .search-icon {
        position: absolute;
        right: 5%;
    }
}

.table-div-dashboard:hover {
    box-shadow: $card_shadow_hover;
}

.card-head {
    font-size: 14px;
    font-weight: 600;
    // padding-right: 10px;
    
}

.meeting-card {
    padding: 0px 10px;
    width: 100%;  
}

.sb-data-box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .meet-detls {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.meet-detls {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.meetings-sort {
    &.select-by-dropdown {
        padding: 1px 6px;
        border: none;
        font-size: $fz15;
        color: rgb(102, 102, 102)
    }

    &:focus {
        outline: none;
    }

    option {
        background-color: $whiteColor;
        border: none;
        outline: none;
    }
}

.meeting-table-header {
    @include displayFlexBetween;
    padding: 5px 5px 10px 5px;
    min-height: 36px;
}

.sort-div {
    @include displayFlex;
}

.totl-company {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selected-graph {
    background: linear-gradient(90deg, $secondaryColor 0%, $secondarySemiLight 35%, $secondaryPaleLight 100%) !important;
    // background-color: $secondaryColor;
    .data-title, .data-value{
    color: $whiteColor !important;
    }
}

.orders-card-div {
    display: flex;
    align-items: center;
    padding: 10px;

    .order-icon-div {
        padding: 8px;
        background: $primaryColor;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: $whiteColor;
    }

    .order-text-div {
        padding: 8px;

        h6 {
            font-size: 13px;
            font-weight: 600;
        }

        font-size: 12px;
    }
}

.react-multiple-carousel__arrow::before, 
.react-multiple-carousel__arrow {
    display: none !important;
  }

  .test-active-body {
    .extend-action-row {
    display: flex;
    align-items: center;
    
        .active-img {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                font-size: 12px;
            }

            // min-width: 120px !important;
        }
    }

    // overflow-x: scroll !important;
    min-width: 400px;

    .active-div-test {
        padding: 12px 5px;
        border-bottom: 1px solid;
        min-width: 150px !important;
        height: 85px;
        max-width: max-content;
        display: flex;
        align-items: center;

        &.active-address-div {
            min-width: 200px !important;
        }
    }

    .badge-section {
        min-width: 120px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .activity-badge {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .blink-effect-active {
        border-radius: 50%;
        left: 65%;
        bottom: 0%;
        animation: blink 1s infinite;
        border: 2px solid transparent;
        animation: blink 1s infinite;
    }
  }

  .act-list-div li {

    // height: 325px !important;
    img {
        width: 100% !important;
        height: 100% !important;
    }
  }

  .dashbord-img-box {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    .dashboard-pop-img {
        margin-top: 30%;
        height: auto
      }

      .carousel-div {
        position: absolute;
        top: 0px;
        width: 45%;

        .carousel-inner {
            padding: 5px;
        }

        .first-popup-div {
            min-height: 60px;
            min-width: 100px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            box-shadow: $card_shadow_hover;
            background: linear-gradient(90deg, $secondaryColor 0%, $secondarySemiLight 35%, $secondaryPaleLight 100%) !important;
            padding: 10px 10px;

            .first-popup-div-text {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                p {
                    color: $whiteColor;
                    font-size: 12px;
                    text-align: center;
                }
            }
        }

        .first-popup-div.active {
            opacity: 0;
            animation: 4.5s anim-popoutin ease;

            &:hover {
                animation-play-state: paused;
              }
        }

          @keyframes anim-popoutin {
            0% {
              transform: scale(0);
              opacity: 0;
              text-shadow: 0 0 0 rgba(0, 0, 0, 0);
            }

            30% {
                transform: scale(1);
                opacity: 1;
                // text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
              }

            50% {
              color: black;
              transform: scale(1);
              opacity: 1;
            }

            70% {
                color: black;
                transform: scale(1);
                opacity: 1;
              }

             100% {
              transform: scale(0);
              opacity: 0;
            }
          }

          .carousel-text2 {
            background-color: $secondarySemiLight;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
          }

          .carousel-text3 {
            background-color: $primarySemiLight;
            transition: opacity 3s ease-in-out;
            transition-delay: 3s;
          }
          
        //   .carousel-text.active {
        //     opacity: 1;
        //   }
      }
      
  }

  .meeting-custom-graph .apexcharts-legend {
    inset: auto 0px 5px 5px;
  }

  .grapg-skeleton {
    height: 50px;
  }

// css for new design