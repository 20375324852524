@import "variables";
@import "mixins";

.attendance-table {
    @include tableProperty;
}

.attendance-monthly-scroll,
.attendance-daily-scroll {
    height: calc(100vh - 200px);
    margin: 10px;
}

.attendance-list-thead {
    @include tableHeadProperty;
}

.page-title.atten-fullday {
    text-align: left;
}

.leave-type-request {
    color: $statusOrange !important;
}

.leave-type-approved {
    color: $statusGreen !important;
}

.attendance-monthly-scroll {
    .attendance-list-row-data {
        td {
            vertical-align: top;
            padding-right: 10px !important;
            white-space: pre;
        }

        td:not(:first-child) {
            color: $navLinkColor;
            font-size: $fz13;
        }

    }
}

.attendance-list-name {
    .table-data-attendance-imgages {
        height: 40px;
        margin-right: 10px;
    }
}

.attendance-daily-scroll .attendance-list-row-data td {
    vertical-align: top;
    padding-right: 10px !important;
}

.login-details .login-time-status,
.logout-time-status {
    color: green;
    margin-bottom: 3px;
    margin-top: 0px;
}

.login-time-location,
.logout-time-location {
    p {
        color: green;
        padding: 3px 0px;
        margin-bottom: 0px;
    }

    i {
        // color: $nav_link_Textcolor;
        color: $secondaryColor;
        padding-right: 5px;
    }
}

// ATTENDENCE BREAKUPS MODAL START

.modal-backdrop-attendance {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $mediumGray;
    z-index: 1040;

    .modal-header {
        border-bottom: 1px solid gray !important;
    }
}

.modal-right-fade {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40%;
    //   background-color: #fff;
    animation: modal-right-fade-animation 0.3s;
    z-index: 1050;
    padding: 10px 0px 10px 10px;
    display: flex;
    align-items: center;
}

.attendance_beakup {
    &.modal-heading {
        font-size: $fz14;
    }
}


.right-fade-modal-dialog {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 8px -1.9px $veryLightMediumGray;
}

.right-fade-modal-dialog .modal-content {
    border: none;
    height: 100%;
    overflow: auto;
    position: inherit;
}

.right-fade-modal-dialog .modal-content::-webkit-scrollbar {
    width: 4px;
}

.right-fade-modal-dialog .modal-content::-webkit-scrollbar-thumb {
    background-color: $secondaryColor;
}

.right-fade-modal-dialog .modal-content .modal-header .btn-close {
    margin: 0px;
    width: 12px;
    height: 12px;
    padding: 0px;
}

.right-fade-modal-dialog .modal-content .modal-header {
    border: none;
}



@keyframes modal-right-fade-animation {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.details-row {
    padding: 10px 5px;
    border-bottom: 1px dotted gray;
}

.contact-footer {
    justify-content: flex-start;
}

// ATTENDENCE BREAKUPS MODAL END



/* SCROLL BAR CSS with Styling START */
/* width */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;

    }
}

/* Track */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.attendance-monthly-scroll,
.attendance-daily-scroll {
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

.holiday-help-container {
    h6 {
        padding: 10px 10px 0px 10px;
        color: $secondaryColor;
        text-decoration: underline;
    }

    i {
        cursor: pointer;
    }

    .holiday-abbreviation {
        display: grid;
        grid-template-columns: 100px 20px 300px;
        padding: 10px;
    }

    .full-day-leave-applied {
        color: $primaryColor;
        font-weight: 700;
    }

    .full-day-leave-rejected {
        color: $secondaryColor;
        font-weight: 700;
    }
}

/* SCROLL BAR CSS with Styling END */