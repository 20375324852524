@import './variables';

.chot-bot-head {
    padding: 0 15px;
}

.live-chat-div {
    position: fixed;
    bottom: 10px;
    // right: 8%;
    right: 2%;
    background-color: $primaryColor;
    // background-image: url('../../public/images/message-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 9999 !important;
    box-shadow: $card_shadow_hover;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    cursor: pointer;
    animation: float 3s ease-in-out infinite;
    animation-delay: 5s;

    .message-box {
        position: relative;
        width: 32px;
        height: 22px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: -2px;   
        // background-color: $primaryColor;
        background-image: url('../../public/images/message-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 9999 !important;
    }

    &:hover {
        .message-box {
            background-image: url('../../public/images/message-icon-fill.svg');
        }

        .anim {
            display: inline-block;
            width: 0.2rem;
            height: 0.2rem;
            box-sizing: border-box;
            background: $primaryColor;
            border-radius: 50%;
            margin: -2px 1px 1px;
        }

        .anim-1 {
            -webkit-animation: anim 3s infinite;
            animation: anim 3s infinite;
        }

        .anim-2 {
            -webkit-animation: anim 3s 250ms infinite;
            animation: anim 3s 250ms infinite;
        }

        .anim-3 {
            -webkit-animation: anim 3s 500ms infinite;
            animation: anim 3s 500ms infinite;
        }
    }
}

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatex(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
        transform: translatex(-8px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
        transform: translatex(0px);
    }
}



.modal-livechat {
    position: fixed;
    top: 30%;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 1040;

    .modal-right {
        position: fixed;
        top: 30%;
        right: 0;
        bottom: 0;
        width: 30%;
        z-index: 1050;
        margin-right: 30px;
        padding: 15px;
        display: flex;
        align-items: center;
    }

    .right-fade-modal-dialog {
        border-top: 4px solid $primaryColor;
        border-bottom: 4px solid $primaryColor;
        width: 100%;
        border-radius: 8px;
        height: 100%;
        position: relative;
        max-width: 100%;
        margin: 0px;
        box-shadow: $card_shadow_hover;
    }

    .right-fade-modal-dialog .modal-content {
        border: none;
        height: 100%;
        overflow: auto;
        position: inherit;
    }

    .right-fade-modal-dialog .modal-content::-webkit-scrollbar {
        display: none;
    }

    .right-fade-modal-dialog .modal-content .modal-header .btn-close {
        margin: 0px;
        width: 12px;
        height: 12px;
        border-radius: 50% !important;

        &:focus {
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .right-fade-modal-dialog .modal-content .modal-header {
        border: none;
        padding: 5px 10px;
        box-shadow: $card_box_shadow;
        -webkit-box-pack: center;
        justify-content: center;
        width: 100%;
        top: 0px;
        position: sticky;
        height: 56px;
        flex-shrink: 0;
        overflow: hidden;
        -webkit-box-flex: 0;
        flex-grow: 0;
        z-index: 2;
        pointer-events: all;
        background-color: $whiteColor;
    }

    .client-modal-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 10%;
        }
    }

    .client-modal-footer {
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: $whiteColor;
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .chat-input {
            padding: 6px 40px 6px 15px !important;
            min-width: 0px !important;
            width: 100% !important;
            -webkit-box-flex: 1 !important;
            max-width: 100% !important;
            font-family: inherit !important;
            overflow-y: auto !important;
            border: none !important;
            resize: none;

            &::-webkit-scrollbar {
                display: none !important;
            }

            &:focus {
                border: none !important;
                outline: none !important;
            }

            &:focus-within {
                border: none !important;
                outline: none !important;
            }
        }

        img {
            padding: 5px;
            border-radius: 50%;
            box-shadow: $card_box_shadow;
            position: absolute;
            right: 10px;
            top: 8px;
            width: 24px;
            cursor: pointer;
        }
    }

    @keyframes modal-right-fade-animation {
        0% {
            transform: translateX(100%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes pulse {
        0% {
            // transform: rotate(-45deg) scale(1);
            opacity: 0;
        }

        /*
    10% {
        transform: rotate(-45deg) scale(1.3);
    }
    20% {
        transform: rotate(-45deg) scale(0.9);
    }
    30% {
        transform: rotate(-45deg) scale(1.2);
    }
    40% {
        transform: rotate(-45deg) scale(0.9);
    }*/

        50% {
            // transform: rotate(-45deg) scale(1.3);
            opacity: 1;
        }

        /*
    60% {
        transform: rotate(-45deg) scale(0.95);
    }
    70% {
        transform: rotate(-45deg) scale(1);
    } */

        100% {
            // transform: rotate(-45deg) scale(1);
            opacity: 1;
        }
    }

    .typing {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0rem;
        box-sizing: border-box;
        background: $primaryColor;
        border-radius: 50%;
        margin: 1px;
    }

    .type-text {
        margin-right: 4px;
        color: $primaryColor;
        // background: $primaryColor;
    }

    .typing-1 {
        -webkit-animation: typing 3s infinite;
        animation: typing 3s infinite;
    }

    .typing-2 {
        -webkit-animation: typing 3s 250ms infinite;
        animation: typing 3s 250ms infinite;
    }

    .typing-3 {
        -webkit-animation: typing 3s 500ms infinite;
        animation: typing 3s 500ms infinite;
    }

    .view-map-text {
        color: $secondaryColor;
        cursor: pointer;
    }

    .details-row {
        padding-bottom: 13px;
    }

    .detail-text {
        color: $primaryColor
    }

    .contact-footer {
        justify-content: flex-start;
    }

    .other-contact-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .other-contact-form {
        position: absolute;
        bottom: 0;
        // padding: 0px 10px;
        width: 100%;
        transition: opacity 0.5s, height 0.5s;

        .contact-form-inner {
            background-color: $whiteColor;
            width: 100%;
            box-shadow: 0px 0px 8px -1.9px $veryLightMediumGray;
            padding: 10px;
        }
    }

    .contact-btn-section {
        display: flex;
    }

    .contact-form-div {
        padding: 10px 0px;
    }

    .other-contacts {
        min-height: 30px;
    }

    .conatct-text {
        padding: 10px;
    }

    .chat-header-text {
        background: $header_Gradient;
        -webkit-background-clip: text;
        color: transparent;
        font-size: $fz14;
        font-weight: 700;
    }

    .chat-body {
        min-height: 50vh;
        padding: 6px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none !important;
        }
    }

    .chat-box-section {
        position: relative;
        display: block;

        &::-webkit-scrollbar {
            display: none !important;
        }

        .message-box-row-right {
            display: flex;
            align-items: flex-start;
            animation: show-chat-odd 0.15s 1 ease-in;
            -moz-animation: show-chat-odd 0.15s 1 ease-in;
            -webkit-animation: show-chat-odd 0.15s 1 ease-in;

            img {
                width: 10%;
                border-radius: 50%;
                border: 2px solid $secondaryColor;
            }
        }

        .message-box-row-left {
            display: flex;
            align-items: flex-start;
            animation: show-chat-even 0.15s 1 ease-in;
            -moz-animation: show-chat-even 0.15s 1 ease-in;
            -webkit-animation: show-chat-even 0.15s 1 ease-in;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                // border: 2px solid $secondaryColor;
            }
        }

        .chat-text-box-right {
            position: relative;
            display: flex;
            justify-content: flex-end;

            margin: 10px 20px;
            width: 100%;
            color: $lightGrayishCyan;

            p {
                padding: 5px;
                box-shadow: $card_box_shadow;
                max-width: 85%;
                border-radius: 4px;
                background-color: $lightGrayishCyan !important;
                color: $secondaryColor !important;
            }

            :before {
                position: absolute;
                top: 0;
                border-radius: 50px;
                content: '';
                right: -80px;
            }

            :after {
                position: absolute;
                top: 15px;
                content: '';
                width: 0;
                height: 0;
                border-top: 15px solid $lightGrayishCyan;
                border-right: 15px solid transparent;
                right: -15px;
            }
        }



        .chat-text-box-left {
            position: relative;
            display: flex;
            justify-content: flex-start;
            margin: 10px 20px;
            color: $lightGrayishCyan;
            width: 100%;

            p {
                box-shadow: $card_box_shadow;
                padding: 5px;
                max-width: 85%;
                border-radius: 4px;
                background-color: $lightGrayishCyan !important;
                color: $primaryColor !important;
            }

            :before {
                position: absolute;
                top: 0;
                border-radius: 50px;
                content: '';
                left: -80px;
            }

            :after {
                position: absolute;
                top: 15px;
                content: '';
                width: 0;
                height: 0;
                border-top: 15px solid $lightGrayishCyan;
                border-left: 15px solid transparent;
                left: -15px;
            }
        }

        .chat-text-box-typing {
            position: relative;
            display: flex;
            justify-content: flex-start;
            margin: 10px 20px;
            width: 100%;
            color: $lightGrayishCyan;

            p {
                box-shadow: $card_box_shadow;
                padding: 5px;
                max-width: 85%;
                border-radius: 4px;
                background-color: $lightGrayishCyan !important;
                color: $primaryColor !important;
            }
        }
    }

    @keyframes show-chat-even {
        0% {
            margin-left: -480px;
        }

        100% {
            margin-left: 0;
        }
    }

    @-moz-keyframes show-chat-even {
        0% {
            margin-left: -480px;
        }

        100% {
            margin-left: 0;
        }
    }

    @-webkit-keyframes show-chat-even {
        0% {
            margin-left: -480px;
        }

        100% {
            margin-left: 0;
        }
    }

    @keyframes show-chat-odd {
        0% {
            margin-right: -480px;
        }

        100% {
            margin-right: 0;
        }
    }

    @-moz-keyframes show-chat-odd {
        0% {
            margin-right: -480px;
        }

        100% {
            margin-right: 0;
        }
    }

    @-webkit-keyframes show-chat-odd {
        0% {
            margin-right: -480px;
        }

        100% {
            margin-right: 0;
        }
    }


    /*executes the hide animation */

    .close-chat {
        -webkit-animation: closebox 0.25s ease-out;
        /* Chrome, Safari, Opera */

        animation: closebox 0.25s ease-out;
        height: 0;
        width: 0;
        // display: none;
    }

    /*executes the expand animation*/

    .show-chat {
        -webkit-animation: openbox 0.25s ease-in;
        /* Chrome, Safari, Opera */

        animation: openbox 0.25s ease-in;
        // height: 100%;
        // width: 30%;
    }

    @-webkit-keyframes openbox {
        0% {
            //   height: 0px;
            width: 0px;
        }

        100% {
            //   height: 300px;
            width: 30%;
        }
    }

    @keyframes openbox {
        0% {
            //   height: 0px;
            top: 100%;
            width: 0px;
        }

        100% {
            //   height: 300px;
            top: 30%;
            width: 30%;
        }
    }

    //   @-webkit-keyframes closebox {
    //     0% {
    //         // height: 100%;
    //     width: 30%;
    //     }
    //     100% {
    //     //   height: 0;
    //       width: 0px;
    //     }
    //   }

    @keyframes closebox {
        0% {
            top: 30%;
            // height: 100%;
            width: 30%;
        }

        100% {
            top: 100%;
            //   height: 0;
            width: 0px;
        }
    }

    @keyframes typing {

        0%,
        75%,
        100% {
            transform: translate(0, 0.15rem) scale(0.9);
            opacity: 0.5;
        }

        25% {
            transform: translate(0, -0.15rem) scale(1);
            opacity: 1;
        }
    }

    @keyframes anim {

        0%,
        75%,
        100% {
            transform: translate(0, 0.1rem) scale(0.9);
            opacity: 0.7;
        }

        25% {
            transform: translate(0, -0.1rem) scale(1);
            opacity: 1;
        }
    }
}