// @import "variables";
// @import "mixins";


// .user-table {
//     @include tableProperty;
// }

// .users-scrollbar {
//     height: calc(100vh - 200px);
//     margin: 10px;
// }

// .user-mobile {
//     color: $primaryColor;
//     font-size: 12px;
// }

// .users-scrollbar::-webkit-scrollbar-thumb {
//     background: $secondaryColor;
//     border-radius: 0;
// }

// .users-list-thead {
//     @include tableHeadProperty;

// }

// .btn-add-user {
//     @include buttonProperty($primaryColor,
//         30px,
//         auto,
//         0,
//         13px,
//         $whiteColor);
//     font-weight: 700;

//     &:hover {
//         color: $whiteColor;
//     }

//     &:focus {
//         box-shadow: none;
//     }

//     h6 {
//         font-size: $fz13;
//         padding-left: 5px;
//     }
// }

// .table-data-users-imgages {
//     @include userImgProperty(35px);
//     margin-right: 10px;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: 0px 0px 6px -0.9px $card_BoxShadow;
//     object-fit: cover;
//     &:hover{
//         box-shadow: 0px 0px 6px -0.4px $card_BoxShadow;
//     }
//     &.default-image{
//         color: $whiteColor;
//         font-weight: 700;
//         font-size: 16px;
//         height: 35px !important;
//         width: 35px !important;
//     }
//     &.company-logo{
//         color: whiteColor;
//         font-weight: 700;
//         font-size: 16px;
//         height: 40px !important;
//         width: 40px !important;
//         object-fit: contain;
//     }
// }

// .users-list-name {
//     @include tableDataImgTxt;

//     h6 {
//         font-size: $tableData_headTextSize;
//     }
// }

// .user-action-td {
//     align-items: center;
//     justify-content: space-around;

//     .user-edit-link,
//     .user-view-link {
//         padding: 5px;
//         text-decoration: none;
//         color: $primaryColor;

//         &:hover {
//             color: $primaryColor;
//         }
//         &.disabled{
//             color: $primaryPaleLight;
//         }
//     }
// }

// .subbordinate-link {
//     color: $OrangeRed;

//     // display: inline-flex;
//     .user-icon {
//         color: $primaryColor;
//         cursor: pointer;
//         padding-left: 5px;
//     }
// }

// .add-single-bulk-user {
//     .single-bulk-upload {
//         transform: translate(0px, 32px) !important;
//         margin-left: 0px !important;
//         margin-top: 0px !important;
//         border-radius: 5px !important;
//         background-color: $blackColor;
//         min-width: max-content !important;
//         font-size: $fz13 !important;

//         li {
//             button {
//                 color: $whiteColor;

//                 &:hover {
//                     color: $secondaryColor;
//                 }
//             }
//         }

//         .dropdown-item:focus,
//         .dropdown-item:active,
//         .dropdown-item:hover {
//             background-color: transparent !important;
//         }
//     }
// }

// .template-download {
//     background-color: $secondaryColor;
//     border-radius: 15px;
//     font-size: $fz13;
//     color: $whiteColor;
//     float: right;

//     &:hover {
//         color: $whiteColor;
//     }
// }

// @media screen and (max-width: 1280px) {
//     .users-list-name {
//         h6 {
//             font-size: 0.8rem;
//         }
//     }
// }

// /* SCROLL BAR CSS with Styling START */
// /* width */
// .users-scrollbar::-webkit-scrollbar {
//     height: 5px;
//     width: 5px;
// }

// /* Track */
// .users-scrollbar::-webkit-scrollbar-track {
//     background: $scrollerBackgroundColor;
// }

// /* Handle */
// .users-scrollbar::-webkit-scrollbar-thumb {
//     background: $secondaryColor;
//     border-radius: 0px;
// }

// /* Handle on hover */
// .users-scrollbar:-webkit-scrollbar-thumb:hover {
//     background: $secondaryColor;
// }

// /* SCROLL BAR CSS with Styling END */









@import "variables";
@import "mixins";


.user-table {
    @include tableProperty;
}

.users-scrollbar {
    height: calc(100vh - 200px);
    margin: 10px;
}

.color-yellow {
    color: $secondaryColor;
    font-size: 12px;
}

.user-mobile {
    color: $primaryColor;
    font-size: 12px;
}

.users-scrollbar::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 0;
}

.users-list-thead {
    @include tableHeadProperty;

}

.btn-add-user {
    @include buttonProperty($primaryColor,
        30px,
        auto,
        0,
        13px,
        $whiteColor);
    font-weight: 700;

    &:hover {
        color: $whiteColor;
    }

    &:focus {
        box-shadow: none;
    }

    h6 {
        font-size: $fz13;
        padding-left: 5px;
    }
}

.table-data-users-imgages {
    @include userImgProperty(35px);
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px -0.9px $card_BoxShadow;
    object-fit: cover;

    &:hover {
        box-shadow: 0px 0px 6px -0.4px $card_BoxShadow;
    }

    &.default-image {
        color: $whiteColor;
        font-weight: 700;
        font-size: 16px;
        height: 35px !important;
        width: 35px !important;
    }

    &.company-logo {
        color: whiteColor;
        font-weight: 700;
        font-size: 16px;
        height: 40px !important;
        width: 40px !important;
        object-fit: contain;
    }
}

.card-data-users-imgages {
    @include userImgProperty(35px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 6px -0.9px $card_BoxShadow;
    object-fit: cover;

    &:hover {
        box-shadow: 0px 0px 6px -0.4px $card_BoxShadow;
    }

    &.default-image {
        color: $whiteColor;
        font-weight: 700;
        font-size: 16px;
        height: 35px !important;
        width: 35px !important;
    }

    &.company-logo {
        color: whiteColor;
        font-weight: 700;
        font-size: 16px;
        height: 40px !important;
        width: 40px !important;
        object-fit: contain;
    }
}

.users-list-name {
    @include tableDataImgTxt;

    h6 {
        font-size: $tableData_headTextSize;
    }
}

.user-action-td {
    align-items: center;
    justify-content: space-around;

    .user-edit-link,
    .user-view-link {
        padding: 5px;
        text-decoration: none;
        color: $primaryColor;

        &:hover {
            color: $primaryColor;
        }

        &.disabled {
            color: $primaryPaleLight;
        }
    }
}

.subbordinate-link {
    color: $OrangeRed;
    display: inline-flex;

    // display: inline-flex;
    .user-icon {
        color: $primaryColor;
        cursor: pointer;
        padding-left: 5px;
    }
}

.add-single-bulk-user {
    .single-bulk-upload {
        transform: translate(0px, 32px) !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
        border-radius: 5px !important;
        background-color: $blackColor;
        min-width: max-content !important;
        font-size: $fz13 !important;

        li {
            button {
                color: $whiteColor;

                &:hover {
                    color: $secondaryColor;
                }
            }
        }

        .dropdown-item:focus,
        .dropdown-item:active,
        .dropdown-item:hover {
            background-color: transparent !important;
        }
    }
}

.template-download {
    background-color: $secondaryColor;
    border-radius: 15px;
    font-size: $fz13;
    color: $whiteColor;
    float: right;
    // padding: 3px 15px;

    &:hover {
        color: $whiteColor;
    }
}

@media screen and (max-width: 1280px) {
    .users-list-name {
        h6 {
            font-size: 0.8rem;
        }
    }
}

/* SCROLL BAR CSS with Styling START */
/* width */
.users-scrollbar::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

/* Track */
.users-scrollbar::-webkit-scrollbar-track {
    background: $scrollerBackgroundColor;
}

/* Handle */
.users-scrollbar::-webkit-scrollbar-thumb {
    background: $secondaryColor;
    border-radius: 0px;
}

/* Handle on hover */
.users-scrollbar:-webkit-scrollbar-thumb:hover {
    background: $secondaryColor;
}

/* SCROLL BAR CSS with Styling END */

// new css for card view
.user-card-view {
    width: 20%;

    .user-card-container {
        height: 270px;
        padding: 5px;
        background-color: $iconBackground;
        transform: translateZ(0);
        transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out !important;
        will-change: transform;

        &:hover {
            box-shadow: $card_shadow_hover;
            transform: scale(1.03);
            transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out !important;
        }

        .emp-details-row {
            z-index: 10101;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            height: 120px;
            justify-content: center;
            padding: 0 10px;

            .empp-img-div {
                // width: 25%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .dot {
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    right: 6px;
                    bottom: 3px;
                    box-shadow: $card_BoxShadow !important;

                    &.active {
                        background-color: $compStatusColor;
                    }

                    &.inactive {
                        background-color: $cancStatusColor;
                    }
                }

                .emp-profile {
                    width: 70px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 25px;
                    font-weight: 800;
                    color: #fff;
                }
            }

            .emp-details-col {
                // width: 75%;
                padding: 5px 10px;
            }
        }

        .emp-contact-div {
            position: relative;
            z-index: 10101;
            overflow: hidden;
            padding: 10px 20px;
            background-color: $iconBackground;
            height: 140px;
            border-radius: 6px;

            .emp-category-div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .category-div-header {
                    font-size: 12px;
                    color: $color-gray;
                }
            }

            .emp-contact-details {
                padding: 10px 0;

                .fa {
                    color: $color-gray;
                }
            }

            .emp-btn-section {
                padding: 5px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    color: $primaryColor;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
}