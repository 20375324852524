@import "variables";
@import "mixins";

.remove-manger{
    font-size: $fz13 !important;
    padding: 5px;
    color: $primaryColor;
    &:hover{
        color: $primaryColor;
        box-shadow: none;
    }
    i{
        padding-right: 3px;
    }
}
.viewsubbordinate-table{
    @include tableProperty;
}

.subbordinate-scroll{
    height: calc(100vh - 180px);
    margin: 10px;
}

/* SCROLL BAR CSS with Styling START */
/* width */
.subbordinate-scroll {
    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
}

/* Track */
.subbordinate-scroll {
    &::-webkit-scrollbar-track {
        background: $scrollerBackgroundColor;
    }
}

/* Handle */
.subbordinate-scroll{
    &::-webkit-scrollbar-thumb {
        background: $scrollerThumbColor;
        border-radius: 0px;
    }
}

/* Handle on hover */
.subbordinate-scroll {
    &:-webkit-scrollbar-thumb:hover {
        background: rgb(102, 102, 102);
    }
}

/* SCROLL BAR CSS with Styling END */

