@import "variables";
@import "mixins";

.service-request-container {
    padding: 20px;
    background-color: #f6f6f6;
    margin: 20px 20px 0px 20px;

    // width: 70%;
    &:hover {
        background-color: $whiteColor;
    }
}

.service-request-first-div img {
    height: 250px;
    width: 250px;
}

.service-request-second-div {
    h5 {
        padding: 20px;
        font-weight: 600;
    }

    ul {
        li {
            font-size: 15px;

            &::marker {
                color: $warningColor;
            }
        }
    }
}

.request-box-div {
    z-index: 1;
    box-shadow: 0px 0px 6px -2.9px $borderColor;
    border-radius: 6px;
    margin-bottom: 20px;
    position: relative;

    .hover-section {
        display: none;
    }

    &:hover {
        box-shadow: 0px 0px 8px -1.9px $borderColor;

        .hover-section {
            padding: 0px 10px;
            position: absolute;
            display: flex;
            align-items: flex-end;
            bottom: 20px;
            // height: 100%;
            right: 30px;
            // background-color: red;
            width: auto;

        }
    }

    .request-id-col {
        display: flex;
        align-items: center;
        // justify-content: center;
        padding: 0px;
    }

    .request-details {
        display: flex;
        justify-content: left;
        position: relative;
        padding: 10px;
        border-bottom: 1px solid $primaryVeryLight;

        .mi-request-id-section {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .request-id-text {
                font-size: 13px;
                color: $textHeadColor;
                padding-bottom: 10px;
            }

            .priority-btn {
                padding: 20px 0 10px;
            }
        }

        .request-service-div {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .mi-service-request-action {
                padding: 10px !important;
                color: $subText_Color !important;

            }

            .date-section-div {
                min-width: 100px;
                padding: 4px;
                text-align: center;
            }
        }

        .prod-name {
            cursor: pointer;
            font-size: 15px;
            font-weight: 700 !important;
            color: $primaryColor !important;
        }

        .service-prod-name {
            // color: $subText_Color;
            color: $secondaryColor;
            font-size: 15px;
        }
    }

    .request-service-icons {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mi-request-action {
            display: flex;
            align-items: center;
            padding: 4px 10px !important;
            cursor: pointer;

            i,
            span {
                font-size: 16px !important;
                color: $textHeadColor !important;
                font-weight: 600;
            }
        }

        .mi-service-request-action {

            padding: 4px 10px !important;
            color: $subText_Color !important;

            .details-sub-value {
                color: $subText_Color !important;
                font-weight: 700;

                .pending-request {
                    color: $danderColor;
                    font-weight: 600;
                }

                .follow-request {
                    color: $followStatusColor;
                    font-weight: 600;
                }

                .complete-request {
                    color: $compStatusColor;
                    font-weight: 600;
                }
            }

            .due-date-details-sub-value {
                color: $vividYellow !important;
                font-weight: 700;
            }
        }
    }

    .br-right {
        border-right: 1px solid $primaryVeryLight;
        padding: 0;
        position: relative;
    }

    .details-sub-header {
        font-size: 13px;
        color: $subText_Color;
    }

    .service-details-div {
        position: relative;

        .action-icons {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}



.service-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    button {
        width: 80px;
        height: 30px;
        border: none;
        background-color: $warningColor;
        color: $whiteColor;
        border-radius: 10px;
        position: absolute;
        right: 250px;
    }
}


/* Arrow styles */
.arrow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    position: relative;

    .arrow-up {
        position: relative;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #333;
        /* Change color as needed */
        margin: 0 auto;
    }

    .arrow-line {
        width: 30px;
        height: 300px;
        background-color: #333;
        position: relative;
    }

    .arrow-text {
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
        color: #fff;
        /* Change color as needed */
        position: absolute;
        rotate: 270deg;
        top: 25%;
        width: 200px;
    }
}


.service-request-buttons button {
    // padding: 4px 10px;
    // min-width: 80px;
    // border-radius: 25px;
    // font-size: 15px;
    // color: #fff;
    // margin: 0px 5px;
    // box-shadow: none;
    // font-weight: 700;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // font-weight: 500;
    min-width: 80px;
    border-radius: 25px;
    font-size: 14px;
    color: #fff;
    margin: 0px 5px;
    box-shadow: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    height: 30px;
}

.button1 {
    background-color: $compStatusColor;
    border: none;

    &:hover {
        background-color: $compStatusColor;
    }

    &:disabled {
        background-color: $compStatusColor;
        cursor: not-allowed;
    }
}

.button2 {
    background-color: $cancStatusColor;
    border: none;

    &:hover {
        background-color: $cancStatusColor;
    }

    &:focus &:focus-within {
        outline: none;
    }
}

.button3 {
    background-color: $commonStatusColor;
    border: none;

    &:hover {
        background-color: $commonStatusColor;
    }

    &:focus &:focus-within {
        outline: none;
    }
}

// new design css
.request-page-header {
    z-index: 999;
    position: relative;

    .header-filter-btn {
        display: none;
    }
}

.request-list-div {
    max-height: 80vh;

    .request-list-div-row {
        position: relative;
        height: 80vh !important;

        .side-filter-col {
            padding: 0 !important;
            height: 100%;

            .side-filter-div {
                height: 100%;
                box-shadow: 2px 2px 2px $primaryPaleLight !important;
                overflow: auto;

                .side-filter-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 20px;
                    border-bottom: 1px solid $primaryPaleLight;

                    .close {
                        display: none;
                    }
                }

                .filters-section {
                    .slt-input-div {
                        padding: 10px 20px;

                        .filter-input {
                            padding-bottom: 10px;

                            .date-picker-with-icon {
                                width: 100% !important;
                            }
                        }

                        .react-select-container-list {
                            margin: 0 !important;
                        }

                        .radio-group {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 10px 0;
                            flex-wrap: wrap;

                            .customRadio {
                                padding-right: 20px;

                                .form-check-input {
                                    border-color: $primaryColor;
                                    // background-color: $danderColor;
                                }

                                .form-check-label {
                                    /* Custom label appearance */
                                }
                            }
                        }


                    }

                    .sub-filter-header {
                        background-color: $primaryVeryLight;
                        padding: 10px 20px;
                        color: $textHeadColor;

                        h6 {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .request-download-section {
            position: sticky !important;
            z-index: 2;
            background-color: $whiteColor !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 50px;
            padding: 6px;
            position: relative;
            bottom: 0;
            right: 0;
        }

        .request-data-div {
            height: 100%;
            overflow: auto;
            padding: 20px;
        }
    }
}

@media screen and (max-width : 1200px) {
    .request-page-header {
        .header-filter-btn {
            display: block !important;
        }
    }

    .side-filter-col {

        // width: 0% !important;
        &.desktop-filter {
            display: none;
        }

        &.resonsive-filter {
            display: block !important;
            width: 25%;
            position: absolute;
            z-index: 99;
            background: $whiteColor !important;
            top: 0;
            left: 0;

            .side-filter-header {
                .close {
                    display: block !important;
                    cursor: pointer;
                }
            }
        }
    }

    .request-data-div {
        width: 100% !important;
    }
}

// new design css

.mi-subform-header {
    // padding-bottom: 2px;
    padding-bottom: 5px 10px;
    // display: flex;
    align-items: center;

    .form-header-sub {
        position: relative;
        margin-bottom: 10px;

        h6 {
            // text-align: center;
            // text-transform: uppercase;
            // padding-bottom: 5px;
        }

        h6:before {
            width: 28px;
            height: 5px;
            display: block;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 3%;
            // margin-left: -14px;
            background-color: $secondaryColor;
        }

        h6:after {
            width: 100px;
            height: 1px;
            display: block;
            content: "";
            position: relative;
            margin-top: 10px;
            left: 50px;
            margin-left: -50px;
            background-color: $secondaryColor;
        }
    }
}

.order-track {
    // padding: 0 1rem;
    // padding-top: 2.5rem;
    padding: 2.2rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;

    &-step {
        display: flex;
        height: 5rem;

        &:last-child {
            // overflow: hidden;
            // height: 5rem;

            & .order-track-status span:last-of-type {
                // display: none;
            }
        }

        &:last-child {
            & .order-track-status-line {
                display: none;
            }
        }
    }

    &-status {
        margin-right: 1.5rem;
        position: relative;

        &-dot {
            display: block;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: 50%;
            background: $vividYellow;

            &::after {
                content: '\2713';
                color: $whiteColor;
                font-size: 1.2rem;
                display: none; // Hide the check mark by default
                text-align: center;
                line-height: 2.0rem;
            }
        }

        &-line {
            display: block;
            margin: 0 auto;
            width: 2px;
            height: 5rem;
            background: $vividYellow;
        }
    }

    &-text {
        &-stat {
            font-size: 1.0rem !important;
            font-weight: 500;
            margin-bottom: 3px;
        }

        &-sub {
            font-size: 0.8rem;
            font-weight: 500;
        }
    }

    // Only show the check mark when the step is marked as complete
    &-step.is-complete {
        .order-track-status-dot::after {
            display: block;
        }
    }
}

// @media print {
//     .order-track {
//         height: auto !important;
//         overflow: visible !important;
//     }
// }

.order-track {
    transition: all .3s height 0.3s;
    transform-origin: top center;
}

.order-track-step.is-complete .order-track-status-dot {
    background-color: $paleGraphGreen;
}

.order-track-step.is-complete .order-track-status-line {
    background-color: $paleGraphGreen;
}

.update-status-badge {
    padding: 10px 90px;
    font-size: 15px;
    font-weight: 700;
    margin-left: 10px;
}

.serviceandrepir-container {

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px;

        h5 {
            font-weight: 600;
        }

        p:nth-child(3),
        p:nth-child(4) {
            text-align: center;
        }
    }

    .service-report-table-div {
        padding: 10px 50px !important;

        .service-report-table {
            border: 2px solid black !important;
        }

        thead tr {
            border-bottom: 1px solid #ded6ff !important;
        }

        // thead tr th {
        //     border-right: 1px solid #ded6ff !important;
        // }

        h5 {
            font-weight: 700;
        }

        h6 {
            font-weight: 700;
        }

        tbody tr th,
        td {
            border-right: 1px solid #ded6ff !important;
        }
    }

    &-footer {
        display: flex;
    }

    b {
        padding: 0px 40px;
    }

    .service-report-name-signature {
        display: flex;
        flex-direction: column;
        align-items: center;

        b {
            padding: 0px 30px 30px;
        }
    }

    .Service-report-dowmload-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        gap: 20px;

        a {
            font-size: 16px;
        }

        i {
            font-size: 16px;
            padding: 5px;
        }

    }

    @media print {
        .no-print {
            display: none;
        }
    }
}

#tooltip-serviceRequest {
    .tooltip-inner {
        background-color: $toolTipBackground !important;
        color: $secondaryColor !important;
        max-width: 500px !important;
    }
}