
@import "variables";

@mixin mainBody {
    width: calc(100% - 15.8vw);
    position: relative;
    margin-left: auto;
    right: 0px;
    min-height: 85vh;
    max-height: auto;
    overflow-x: hidden;
    background: $whiteColor;
    transition: all 0.35s ease;
    transform: translateX(0);
}


@mixin toggleShiftLeft {
    width: calc(100% - 5.8vw);
    position: relative;
    right: 0px;
    margin-left: auto;
    transition: all 0.35s ease;
    transform: translateX(0);
}

@mixin paddingMarginSetZero($padd, $marg) {
    padding: $padd !important;
    margin: $marg !important;
}

@mixin pageContentProperty {
    position: relative;
    right: 0px;
    background-color: $whiteColor;
    border-top-color: #f4fffb;
    border-top-width: 1px;
    border-top: 2px solid #f4fffb;
    border: 0px;
    margin: 0.2rem 0.35rem;
    height: auto;
    box-shadow: 0px 0px 8px -1.9px $borderColor;
}

@mixin tableHeadProperty {
    background-color: $whiteColor !important;
    color: $tableHead_Color !important;
    font-size: $fz13;
    font-weight: $fwt500;
    width: max-content;
    vertical-align: middle !important;
    position: sticky;
    top: 0;
    // border-bottom: $table_titleTop_borderBottom;
}

@mixin tableBodyProperty {
    background-color: $whiteColor !important;
    padding: 5px !important;
    font-size: $fz13;
}

@mixin tableProperty {
    position: relative;
    width: 100%;
    height: auto;
    background-color: transparent;
}

@mixin pageTitle {
    flex: auto;
    font-weight: $fwt700;
    margin-left: 10px;
}

@mixin selectingMenus {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 6px 10px;
    position: sticky;
    top: 0;
    box-shadow: $topBarShadow;
}

@mixin SelectDropdowns {
    // margin: 5px;
    height: 30px;
    font-size: $fz13;
    // max-width: max-content !important;
    border-radius: 5px;
    background-color: $whiteColor;
    border: none!important;
    box-shadow: $card_shadow_hover;
}

@mixin hvCenterUsingPositionAbsolute {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

@mixin buttonProperty($bgColor, $height, $width, $radius, $txtSize, $txtColor) {
    background-color: $bgColor;
    width: $width;
    border-radius: $radius;
    font-size: $txtSize;
    color: $txtColor;
    box-shadow: none;
}

@mixin addButtonProperty($bgColor, $width, $radius, $txtSize, $txtColor, $pad1, $pad2) {
    background-color: $bgColor;
    padding: $pad1 $pad2;
    min-width: $width;
    border-radius: $radius;
    font-size: $txtSize;
    color: $txtColor;
    margin: 0px 5px;
    box-shadow: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin bulkUploadButtonProperty($bgColor,
    $width,
    $radius,
    $txtSize,
    $txtColor,
    $pad) {
    background-color: $bgColor;
    padding: $pad;
    min-width: $width;
    border-radius: $radius;
    font-size: $txtSize;
    color: $txtColor;
}


@mixin userImgProperty($width) {
    width: $width;
    height: $width;
    opacity: 1;
}

@mixin tableDataImgTxt {
    display: inline-flex;
    align-items: center;
    padding: 3px;
    vertical-align: middle;
}

@mixin modelHeader {
    background-color: $whiteColor;
    align-items: center;
    padding: 14px 15px;
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: space-between;
    color: $primaryColor;
    border-radius: 6px 6px 0px 0px;
}

@mixin modelFooter {
    background-color: $whiteColor;
    padding: 10px 10px 10px 15px;
    border-top: 1px solid $borderColor;
    display: flex;
    justify-content: flex-end;
    border-radius: 0px 0px 6px 6px; 
}

@mixin displayFlex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin displayFlexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}