@import "variables";
@import "mixins";

.main-without-sidebar {
    .form-field-item-hover-div {
        &:hover {
            color: $primaryColor !important;
            background-image: $sidebarActive;
        }
    }

    .form-field-item {
        // background-color: $primaryVeryLight;
        // padding: 10px;
        margin: 1px;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        border-bottom-left-radius: 22px;
        border-top-left-radius: 22px;
        // background-image: linear-gradient(to right, rgba(183, 169, 248, 0.598) 30%, transparent 100%) !important;

        .item-icons {
            height: 35px !important;
            width: 35px !important;
            border-radius: 50%;
            padding: 6px;
            // border: 1px solid $primaryColor;
            // background-color: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        .item-text {
            // color: $primaryColor;
            // padding: 10px;
            // width: 100%;

            color: $primaryText_Color;
            margin-left: 8px;
            font-size: $fz14;
            font-weight: $fwt400;
        }
    }

    .main-tab-div {
        .tab-content {
            height: 95%;

            .form-main-tab {
                height: 100%;
            }
        }
    }

    .sub-tab-div {
        height: 100%;

        .tab-content {
            min-height: 94.5%;
            max-height: 94.5%;

            .form-main-tab {
                height: 100%;
            }
        }
    }


    .custom-form-tab {
        overflow: hidden;
        // background-image: $tab_Gradient;
        display: flex;
        justify-content: center;
        height: 8%;
        background-color: $primaryColor;

        .nav-item {
            flex-grow: 0 !important;
            min-width: 200px !important;
            border-radius: 0 !important;
            height: 100%;
        }

        &.nav-tabs .nav-link {
            border-color: $primaryPaleLight !important;
            // background: $primaryPaleLight !important;
            background: $primaryColor !important;
            // color: $primaryColor;
            color: $whiteColor;
            font-size: 16px;
            font-weight: 700;
            height: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 !important;

            // min-width: 200px !important;
            &.active {
                border-color: header_Gradient !important;
                // background-image: $header_Gradient !important;
                background-color: $secondaryColor !important;
                color: #fff !important;
                border: none !important;
                border-radius: 0 !important;
            }
        }
    }



    .custom-form-row {
        height: 100%;

        .form-element-col {
            padding: 0 !important;
            height: 100%;

            .form-elements-header {
                padding: 10px;
                // background-color: $primaryColor;
                border-bottom: 1px solid $primaryVeryLight;
                border-right: 1px solid $primaryVeryLight;
                // color: $whiteColor;
                color: $primaryColor;
                text-align: center;
                min-height: 5%;

                h5 {
                    font-weight: 700 !important;
                }
            }

            .form-elements-box {
                // padding: 1px 10px 30px;
                overflow: auto;
                // max-height: 95%;
                // min-height: 95%;
                max-height: 87%;
                min-height: 87%;
                background-color: $whiteColor !important;
                box-shadow: 2px 2px 2px $primaryPaleLight !important;
            }

            .form-setting-box {
                padding: 1px 10px 30px;
                overflow: auto;
                max-height: 100%;
                min-height: 100%;
                box-shadow: 2px 2px 2px $primaryPaleLight !important;

                .nav-pills .nav-link {
                    background-color: $primaryPaleLight !important;
                    border-radius: 0 !important;
                    border-bottom: 1px solid $primaryColor;
                    color: #fff;

                    &.active {
                        background-color: $primaryColor !important;
                    }
                }
            }

            .field-edit-tabs {
                height: 100%;
                overflow: auto;
                background-color: $primarySemiLight;

                .item-edit-section {
                    padding: 20px;
                    border-bottom: 1px solid $primaryColor;

                    label {
                        color: $whiteColor;
                        font-size: 14px;
                    }

                    p {
                        color: $whiteColor;
                    }

                    .form-control {
                        background-color: $primaryColor !important;
                        color: $whiteColor;
                    }

                    .align-btn {
                        flex-wrap: wrap;

                        .btn-check {

                            &:focus {
                                outline: none !important;
                                box-shadow: none !important;
                            }
                        }

                        .btn-check:focus+.btn-primary,
                        .btn-primary:focus {
                            box-shadow: none !important;
                        }

                        .align-label {
                            background-color: $primaryColor;
                            border: none !important;
                            border-radius: 0;
                            max-width: max-content;
                        }

                    }

                    .align-btn .btn.active,
                    .align-btn .btn:focus,
                    .align-btn .btn:active,
                    .align-btn .align-label.active {
                        background-color: $secondaryColor !important;
                        border-color: $secondaryColor !important;
                    }

                    .switch-group {
                        .btn-primary {
                            background-color: $primaryColor;
                            border-color: $primaryColor !important;
                        }

                        .btn-light {
                            background-color: $textHeadColor;
                            color: $whiteColor !important;
                            border-color: $textHeadColor !important;
                        }
                    }

                    .btn-field-dublicate {
                        padding: 4px 10px;
                        background-color: $primaryColor;
                        border-radius: $textHeadColor;
                        color: $whiteColor;
                        border-radius: 2px !important;
                    }

                    .opt-value-div {
                        padding: 5px 4px;
                        border: 1px solid $secondaryColor;
                        border-radius: 4px;
                        max-height: 200px;
                        overflow: auto;

                        .rmv-default {
                            padding: 2px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: $secondaryColor;
                            font-weight: 800;
                            cursor: pointer;
                        }
                    }

                    .add-options {
                        padding: 5px;
                        color: $secondaryColor;
                        font-weight: 800;
                        cursor: pointer;
                    }
                }
            }

        }

        .form-edit-col {
            padding: 10px 40px;
            height: 100%;

            .form-box {
                background-color: #fff;
                height: 100%;

                .form-header-card {
                    border-bottom: 1px solid $primaryColor;
                    height: 15%;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                }

                .form-header {
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    .header-item {
                        padding: 5px;
                        width: 95%;
                        margin: 0;
                        padding: 0;

                        .item-col-data {
                            padding: 10px 20px;
                            position: relative;

                        }
                    }

                    .action-btn-section {
                        position: absolute;
                        top: 2px;
                        right: -15px;
                        padding: auto;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;

                        .icon-div {
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background-color: $whiteColor;
                            margin-bottom: 2px;
                            font-size: 16px;
                            font-weight: 900;
                            cursor: pointer;
                            z-index: 9999;
                            border: 1px solid $primaryColor;

                            .fa-trash-o {
                                color: $danderColor;
                            }

                            &:hover {
                                background-color: $primaryColor !important;
                                color: $whiteColor !important;
                            }
                        }
                    }
                }

                .item-select {
                    border: 1px solid $primaryColor !important;
                    border-radius: 6px;
                }

                .custom-form-control {
                    border: none !important;
                    background-color: transparent !important;
                    box-shadow: none !important;
                    padding: 0;
                    // font-size: 28px;
                    font-weight: 600;

                    &.font-default {
                        font-size: 28px;
                    }

                    &.font-large {
                        font-size: 32px;
                    }

                    &.font-small {
                        font-size: 22px;
                    }
                }

                .control-form-group {
                    &.top {
                        display: block;
                    }

                    &.left {
                        display: flex;
                        align-items: center;

                        .control-form-label {
                            padding-right: 10px;
                            text-wrap: nowrap;
                        }
                    }

                    &.right {
                        display: flex;
                        align-items: center;
                        flex-direction: row-reverse;

                        .control-form-label {
                            padding-left: 10px;
                            text-wrap: nowrap;
                        }
                    }

                    .fullname-form-control {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .sublabel-container {
                            padding-right: 10px;
                            width: 100%;
                        }

                        .drop-area {
                            width: 100%;
                        }

                        .country-code {
                            padding-right: 10px;
                            width: 30%;
                        }

                        .meeting-date-picker-with-icon {
                            width: 100%;
                        }

                        .react-select-list__control,
                        .select-add-meeting-model.meeting-date {
                            height: 30px !important;

                        }

                        .datepicker-placeholder {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 30px !important;
                            display: flex;
                            align-items: center;
                            padding: 0px 10px;
                            pointer-events: none;
                            color: #ccc;
                            /* Align it with your input field's text */
                        }
                    }

                }

                .control-btn {
                    display: flex;
                    align-items: center;

                    &.top {
                        justify-content: center;
                    }

                    &.right {
                        justify-content: flex-end;
                    }

                    &.left {
                        justify-content: flex-start;
                    }

                    .custom-form-btn {
                        background-color: $primaryColor;
                        border: none;
                    }
                }

                .control-divider {
                    hr {
                        background-color: $primaryColor;
                    }
                }

                // }

                .drag-drop-box {
                    padding: 20px;
                    text-align: center;
                }

                .form-setting-element {
                    padding: 10px 20px;
                    border-bottom: 1px solid $primarySemiLight;

                    .switch-group {
                        .btn-primary {
                            background-color: $primaryColor;
                            border-color: $primaryColor !important;
                        }

                        .btn-light {
                            background-color: $textHeadColor;
                            color: $whiteColor !important;
                            border-color: $textHeadColor !important;
                        }
                    }

                    .enable {
                        font-size: 16px;
                        font-weight: 700;
                        color: $successColor;
                    }

                    .disable {
                        font-size: 16px;
                        font-weight: 700;
                        color: $secondaryColor;
                    }

                    .form-setting-control {
                        width: 30%;
                    }
                }
            }

            .field-item-card {
                border-radius: 4px;
            }
        }

        .edit-field-tabs {
            min-height: 5%;

            .nav-item {
                height: 100%;
                border-radius: 0 !important;
            }

            &.nav-tabs .nav-link {
                border-color: $primaryPaleLight !important;
                background: $primaryPaleLight !important;
                height: 100%;
                border-color: transparent !important;
                color: $primaryColor;
                font-size: 16px;
                font-weight: 700;
                border-radius: 0 !important;

                // min-width: 200px !important;
                &.active {
                    border-color: transparent !important;
                    background-image: $header_Gradient !important;
                    color: #fff !important;
                    border: none !important;
                    border-radius: 0 !important;
                }
            }
        }
    }
}

.form-setting-inner-div {
    padding: 10px 10px;
    border-bottom: 1px solid $primarySemiLight;
}