@import './variables';

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $mediumGray;
  z-index: 1040;
}

.modal-right-fade {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40%;
//   background-color: #fff;
  animation: modal-right-fade-animation 0.3s;
  z-index: 1050;
  padding: 10px 0px 10px 10px;
  display: flex;
  align-items: center;
}
.modal-right-fade-bankdetails {
  position: fixed;
  top: 20%;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 60%;
  animation: modal-right-fade-animation 0.3s;
  z-index: 1050;
  padding: 10px 0px 10px 10px;
  display: flex;
  align-items: center;
  .modal-body {
    padding: 0 1rem;
  }
}

.right-fade-modal-dialog {
    width: 100% ;
    height: 100%;
    position: relative;
    max-width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 8px -1.9px $veryLightMediumGray;
}
.right-fade-modal-dialog .modal-content {
    border: none;
    height: 100%;
    overflow: auto;
    position: inherit;
}
.right-fade-modal-dialog .modal-content::-webkit-scrollbar {
    width: 4px;
}
.right-fade-modal-dialog .modal-content::-webkit-scrollbar-thumb {
    background-color: $secondaryColor;
}
.right-fade-modal-dialog .modal-content .modal-header .btn-close {
    margin: 0px;
    width: 12px;
    height: 12px;
    padding: 0px;
}

.right-fade-modal-dialog .modal-content .modal-header {
    border: none;
}
.client-modal-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@keyframes modal-right-fade-animation {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.view-map-text {
    color: $secondaryColor;
    cursor: pointer;
}
.details-row {
    padding-bottom: 13px;
}
.detail-text {
    color: $primaryColor
}
.contact-footer {
    justify-content: flex-start;
}
.other-contact-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.other-contact-form {
    position: absolute;
    bottom: 0;
    // padding: 0px 10px;
    width: 100%;
    transition: opacity 0.5s, height 0.5s;

    .contact-form-inner {
        background-color: $whiteColor;
        width: 100%;
        box-shadow: 0px 0px 8px -1.9px $veryLightMediumGray;
        padding: 10px;
    }
}
.contact-btn-section {
    display: flex;
}
.contact-form-div {
    padding: 10px 0px;
}
.other-contacts {
    min-height: 30px;
}

.conatct-text {
    padding: 10px;
}

